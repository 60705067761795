.fontsize {

    font-size: smaller;
    color: aqua;

}

.p-speeddial-button.p-button.p-button-icon-only {
    width: 35px;
    height: 35px;
    color: #989a9b;
    border: 1px solid white;
}

.cboder {
    border: 2px solid #989a9b;
    margin-bottom: 5px;
}
.algimenCenter{
    text-align: center;
}